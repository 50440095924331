import {
    Alert,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import {useState} from "react";
import SendIcon from "@mui/icons-material/Send";
import {
    AddCircleOutlineRounded,
    RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import keycloak from "../keycloak/Keycloak";
import ArticleDropdown from "../components/ArticleDropdown";
import {useDispatch} from "react-redux";
import {historyActions} from "../util/store/history.slice";

export default function Startseite() {
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [note, setNote] = useState("");
    const [article, setArticle] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });

    const baseUrl = `${process.env.REACT_APP_API_URL}`;

    const dispatch = useDispatch();

    const submitHistory = async () => {
        if (loading) return;

        if (article === "" || price === "") {
            setSnackbar({
                open: true,
                message: "Bitte Artikel und Preis ausfüllen",
                severity: "warning",
            });
            return;
        }

        setLoading(true);
        let date = new Date();

        const result = await fetch(`${baseUrl}/histories`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${keycloak.token}`
            },
            body: JSON.stringify({
                article: article,
                price: price,
                quantity: quantity,
                note: note || null,
                date: new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString(),
            }),
        }).catch((error) => {
            setLoading(false);
            setSnackbar({
                open: true,
                message: "Fehler beim Speichern. Bitte versuche es erneut.",
                severity: "error",
            });
        });

        if (result.status !== 201) {
            return;
        }

        // focus "article-autocomplete" input field
        document.getElementById("article-autocomplete").focus();

        // reset form
        setPrice(0);
        setQuantity(1);
        setNote("");
        setArticle("");
        setLoading(false);

        dispatch(historyActions.addHistory(await result.json()));

        // show success snackbar
        setSnackbar({
            open: true,
            message: "Erfolgreich gespeichert",
            severity: "success",
        });
    };

    const onArticleChange = (e, newValue) => {
        setArticle(newValue);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                height: "60vH",
                minHeight: "600px",
                minWidth: "100vW",
                flexDirection: "column",
            }}
        >
            <Typography variant="h1">Startseite</Typography>
            <Box sx={{display: "flex", flexDirection: "column", width: "60ch"}}>
                <Box sx={{width: "100%"}}>
                    <ArticleDropdown
                        onChange={onArticleChange}
                        value={article}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("price").focus();
                            }
                        }}
                    />
                </Box>

                <Box sx={{display: "flex", flexDirection: "row", mt: 2}}>
                    <TextField
                        id="price"
                        label="Preis"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={price}
                        onChange={(e) => {
                            if (e.target.value >= 0) setPrice(e.target.value);
                            else setPrice(0);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("quantity").focus();
                            }
                        }}
                        sx={{
                            mr: 2,
                            "& .MuiInputBase-input": {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            },
                        }}
                        // only allow positive numbers and step of 100
                        inputProps={{min: 0, step: 100}}
                        // add minus and plus buttons
                        InputProps={{
                            inputProps: {
                                style: {textAlign: "center"},
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="increase price"
                                        onClick={() => {
                                            if (price === "") {
                                                setPrice(100);
                                                return;
                                            }
                                            // prevent string concatenation
                                            setPrice((prevPrice) => prevPrice + 100);
                                        }}
                                    >
                                        <AddCircleOutlineRounded/>
                                    </IconButton>
                                </InputAdornment>
                            ),

                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="decrease price"
                                        onClick={() => {
                                            if (price - 100 >= 0) {
                                                setPrice((prevPrice) => prevPrice - 100);
                                            } else if (price > 0) {
                                                setPrice(0);
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineRounded/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="quantity"
                        label="Menge"
                        variant="outlined"
                        type="number"
                        fullWidth
                        // only allow positive numbers
                        inputProps={{min: 1, step: 1}}
                        value={quantity}
                        onChange={(e) => {
                            if (e.target.value >= 1) setQuantity(e.target.value);
                            else setQuantity(1);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                // focus next input field
                                document.getElementById("note").focus();
                            }
                        }}
                        sx={{
                            ml: 2,
                            "& .MuiInputBase-input": {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            },
                        }}
                        InputProps={{
                            inputProps: {
                                style: {textAlign: "center"},
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="increase quantity"
                                        onClick={() => {
                                            // prevent string concatenation
                                            setQuantity((prevQuantity) => prevQuantity + 1);
                                        }}
                                    >
                                        <AddCircleOutlineRounded/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="decrease quantity"
                                        onClick={() => {
                                            if (quantity > 1) {
                                                setQuantity((prevQuantity) => prevQuantity - 1);
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineRounded/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <TextField
                    id="note"
                    label="Notiz"
                    variant="outlined"
                    fullWidth
                    value={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            // focus next input field
                            document.getElementById("submit").focus();
                        }
                    }}
                    sx={{mt: 2}}
                    multiline
                    rows={4}
                />
                <Button
                    id="submit"
                    variant="outlined"
                    endIcon={<SendIcon/>}
                    sx={{mt: 4, width: "50%", alignSelf: "center"}}
                    onClick={submitHistory}
                >
                    {loading ? "Lädt..." : "Hinzufügen"}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({open: false, message: "", severity: "success"})}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert
                    onClose={() => setSnackbar({open: false, message: "", severity: "success"})}
                    severity={snackbar.severity}
                    sx={{width: "100%"}}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
