import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import keycloak from "../../keycloak/Keycloak";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const namespace = "articles";

const initialState = {
    articles: [],
    loading: false,
    error: null,
};

export const fetchArticles = createAsyncThunk(
    namespace + "/fetchArticles",
    async () => {
        const result = await fetch(`${baseUrl}/articles`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + keycloak.token,
            },
        });

        if (!result.ok) {
            throw new Error("Could not fetch articles.");
        } else {
            return await result.json();
        }
    }
);

const articlesSlice = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        addArticle: (state, action) => {
            state.articles.push(action.payload);
        },
        removeArticle: (state, action) => {
            state.articles = state.articles.filter(
                (article) => article.articleCode !== action.payload.articleCode
            );
        },
        updateArticle: (state, action) => {
            const index = state.articles.findIndex(
                (article) => article.articleCode === action.payload.articleCode
            );
            state.articles[index] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchArticles.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchArticles.fulfilled, (state, action) => {
            state.articles = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(fetchArticles.rejected, (state, action) => {
            state.error = action.error;
            state.loading = false;
        });
    },
});

export const articleActions = {
    ...articlesSlice.actions,
};
export default articlesSlice.reducer;
