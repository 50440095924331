import {
  AddCircleOutlineRounded,
  RemoveCircleOutlineRounded,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import keycloak from "../../keycloak/Keycloak";
import { historyActions } from "../../util/store/history.slice";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export default function EditHistoryPopup(props) {
  const [price, setPrice] = useState(props.history.price);
  const [quantity, setQuantity] = useState(props.history.quantity);
  const [note, setNote] = useState(props.history.note);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitHistory = async () => {
    if (loading) return;

    setLoading(true);

    // transform above to fetch
    const result = await fetch(`${baseUrl}/histories/${props.history.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
      body: JSON.stringify({
        article: props.history.article,
        price: price,
        quantity: quantity,
        note: note || null,
        date: new Date().toISOString(),
      }),
    });

    if (result.ok) {
      dispatch(
        historyActions.updateHistory({
          id: props.history.id,
          article: props.history.article,
          price: price,
          quantity: quantity,
          note: note || null,
          date: new Date().toISOString(),
          username: props.history.username,
        })
      );

      props.setSnackbar({
        open: true,
        message: "Historie erfolgreich aktualisiert",
        severity: "success",
      });
    } else {
      props.setSnackbar({
        open: true,
        message: "Historie konnte nicht aktualisiert werden",
        severity: "error",
      });
    }
 
    props.setSelectedHistory("");
    setLoading(false);
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        if (!loading) {
          props.onClose();
        }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Historie bearbeiten</Typography>
        <Divider sx={{ mt: 2 }} />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <TextField
            id="price"
            label="Preis"
            variant="outlined"
            type="number"
            fullWidth
            value={price}
            onChange={(e) => {
              if (e.target.value >= 0) setPrice(e.target.value);
              else setPrice(0);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                // focus next input field
                document.getElementById("quantity").focus();
              }
            }}
            sx={{
              mr: 2,
              "& .MuiInputBase-input": {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
            // only allow positive numbers and step of 100
            inputProps={{ min: 0, step: 100 }}
            // add minus and plus buttons
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="increase price"
                    onClick={() => {
                      if (price === "") {
                        setPrice(100);
                        return;
                      }
                      // prevent string concatenation
                      setPrice((prevPrice) => prevPrice + 100);
                    }}
                  >
                    <AddCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
              ),

              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="decrease price"
                    onClick={() => {
                      if (price - 100 >= 0) {
                        setPrice((prevPrice) => prevPrice - 100);
                      } else if (price > 0) {
                        setPrice(0);
                      }
                    }}
                  >
                    <RemoveCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="quantity"
            label="Menge"
            variant="outlined"
            type="number"
            fullWidth
            // only allow positive numbers
            inputProps={{ min: 1, step: 1 }}
            value={quantity}
            onChange={(e) => {
              if (e.target.value >= 1) setQuantity(e.target.value);
              else setQuantity(1);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                // focus next input field
                document.getElementById("note").focus();
              }
            }}
            sx={{
              ml: 2,
              "& .MuiInputBase-input": {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            }}
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="increase quantity"
                    onClick={() => {
                      // prevent string concatenation
                      setQuantity((prevQuantity) => prevQuantity + 1);
                    }}
                  >
                    <AddCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="decrease quantity"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity((prevQuantity) => prevQuantity - 1);
                      }
                    }}
                  >
                    <RemoveCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <TextField
          id="note"
          label="Notiz"
          variant="outlined"
          fullWidth
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // focus next input field
              document.getElementById("submit").focus();
            }
          }}
          sx={{ mt: 2 }}
          multiline
          rows={4}
        />
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            props.setSelectedHistory("");
            props.onClose();
          }}
          variant="outlined"
          color="secondary"
        >
          Abbrechen
        </Button>
        <Button onClick={submitHistory} variant="outlined" color="primary">
          {loading ? "Lädt..." : "Speichern"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
