import { AppBar, Box, Button, Toolbar } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import keycloak from "../keycloak/Keycloak";

const menuItems = ["Startseite", "Historien", "Artikel", "ToDo"];

const menuIcons = [
  <HomeOutlinedIcon />,
  <ManageSearchOutlinedIcon />,
  <ArticleOutlinedIcon />,
  <PlaylistAddCheckIcon />,
];

export default function Nav(props) {
  return (
    <AppBar
      position="static"
      color={"transparent"}
      sx={{
        boxShadow: "1 1 1 1",
        bgcolor: "lightGrey",
      }}
    >
      <Toolbar>
        {/* menu items */}
        <Box flexGrow={1} />
        <Box
          sx={{
            display: "flex",
            flexGrow: "1",
            flexShrink: "1",
            flexBasis: "0",
          }}
        >
          {menuItems.map((item, index) => (
            <Button
              key={index}
              size="large"
              edge="start"
              color="inherit"
              aria-label={item}
              variant={"outlined"}
              sx={{
                mx: 2,
                flexBasis: "100%",
                "&:hover": {
                  backgroundColor: "grey.100",
                },
                // if current selection
                ...(props.currentSelection === index && {
                  backgroundColor: "grey.100",
                }),
              }}
              onClick={() => props.setCurrentSelection(index)}
            >
              {menuIcons[index]}
              <Box mr={1} />
              {item}
            </Button>
          ))}
        </Box>
        <Box flexGrow={1} />
      </Toolbar>

      <Box
        sx={{
          position: "absolute",
          top: "1rem",
          right: "1rem",
        }}
      >
        <Button
          variant="outlined"
          startIcon={<LogoutOutlinedIcon />}
          onClick={() => {
            keycloak.logout();
          }}
          sx={{ 
            textTransform: "none",

            // light red border and font
            borderColor: "red",
            color: "red",

            // on hover background red and font white
            "&:hover": {
                backgroundColor: "lightpink",
                color: "white",
                borderColor: "red",
            }
         }}
        >
          Logout
        </Button>
      </Box>
    </AppBar>
  );
}
