import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDispatch } from "react-redux";
import keycloak from "../../keycloak/Keycloak";
import { todoActions } from "../../util/store/todo.slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export default function AddToDoPopup(props) {
  const availableAssignees = [
    { label: "niemanden", value: 0 },
    { label: "bianka", value: 1 },
    { label: "emese", value: 2 },
    { label: "ildi", value: 3 },
    { label: "moni", value: 4},
    { label: "vivi", value: 5 },
    { label: "reka", value: 6 },
];

  const [title, setTitle] = useState("");

  const [description, setDescription] = useState("");

  const [dueDate, setDueDate] = useState("");

  const [assignee, setAssignee] = useState(availableAssignees[0].value);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submitToDo = async () => {
    if (loading) {
      return;
    }

    if (title.length === 0) {
      props.setSnackbar({
        open: true,
        message: "Titel darf nicht leer sein",
        severity: "warning",
      });
      return;
    }

    setLoading(true);

    let result = await fetch(`${baseUrl}/toDos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
      body: JSON.stringify({
        title: title,
        description: description,
        dueDate: dueDate,
        assignee: assignee === 0 ? null : availableAssignees[assignee].label,
      }),
    }).catch((error) => {
      props.setSnackbar({
        open: true,
        message: "Element konnte nicht hinzugefügt werden",
        severity: "error",
      });
    });

    if (result.ok) {
      let newToDo = await result.json();

      dispatch(todoActions.addToDo(newToDo));

      props.setSnackbar({
        open: true,
        message: "ToDo erfolgreich hinzugefügt",
        severity: "success",
      });
    } else {
      props.setSnackbar({
        open: true,
        message: "Element konnte nicht hinzugefügt werden",
        severity: "error",
      });
    }

    resetForm();
    setLoading(false);
    props.onClose();
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setDueDate(new Date());
    setAssignee("");
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        if (!loading) {
          props.onClose();
        }
      }}
      fullWidth
    >
      <Typography variant="h5" sx={{ p: 2 }}>
        Neues ToDo Element
      </Typography>
      <Divider sx={{ mt: 2 }} />
      <Box
        sx={{
          p: 2,
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          id="title"
          label="Titel"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          helperText={`${title.length}/50 Zeichen`}
          required
          inputProps={{ maxLength: 50 }}
          error={title.length > 50}
        />
        <TextField
          id="description"
          label="Inhalt"
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          helperText={`${description.length}/1000 Zeichen`}
          inputProps={{ maxLength: 1000 }}
          multiline
          rows={4}
          error={description.length > 1000}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} id="dueDate">
              <DesktopDateTimePicker
                label="Fälligkeitsdatum"
                onChange={(date) => {
                  setDueDate(date);
                }}
                helperText="Fälligkeitsdatum"
                ampm={false}
                disablePast
                format="DD.MM.YYYY HH:mm"
              />
            </LocalizationProvider>
          </Box>
          <TextField
            id="assignee"
            label="Zuständig"
            variant="outlined"
            fullWidth
            select
            value={assignee}
            onChange={(e) => {
              setAssignee(e.target.value);
            }}
          >
            {availableAssignees.map((assignee) => (
              <MenuItem key={assignee.value} value={assignee.value}>
                {assignee.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <DialogActions>
        <Button
          onClick={() => {
            if (loading) return;
            resetForm();
            props.onClose();
          }}
          variant="outlined"
          color="secondary"
        >
          Abbrechen
        </Button>
        <Button onClick={submitToDo} variant="outlined" color="primary">
          {loading ? "Lädt..." : "Hinzufügen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
