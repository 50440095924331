import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useState } from "react";

export default function DatePickerDialog(props) {
    const [date, setDate] = useState(new Date(props.value));

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Wähle ein Datum</DialogTitle>
            <DialogContent>
                <TextField
                    id="date"
                    label="Datum"
                    value={date.toISOString().split("T")[0]}
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    // only allow dates in the past
                    inputProps={{
                        max: new Date().toISOString().split("T")[0],
                    }}
                    margin="dense"
                    fullWidth
                    onChange={(e) => {
                        const date = new Date(e.target.value);

                        if (date.toString() !== "Invalid Date") setDate(date);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} variant="outlined" color="secondary">
                    Abbrechen
                </Button>
                <Button
                    onClick={() => {
                        props.onDateChange(date.toISOString().split("T")[0]);
                        props.onClose();
                    }}
                    variant="outlined"
                    color="primary"
                >
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
}
