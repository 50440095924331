import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { todoActions } from "../../util/store/todo.slice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import keycloak from "../../keycloak/Keycloak";

export default function ToDoItem(props) {
  const dispatch = useDispatch();

  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const [expanded, setExpanded] = useState(false);

  const setSnackbar = props.setSnackbar;

  const [loadingStatusChange, setLoadingStatusChange] = useState(false);

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: "",
    loading: false,
    onConfirm: () => {},
  });

  const [swipe, setSwipe] = useState(false);

  const onCloseDialog = () => {
    if (dialog.loading) {
      return;
    }
    setDialog({ open: false });
  };

  const confirmationDialog = (
    <Dialog open={dialog.open} onClose={onCloseDialog}>
      <DialogTitle>{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog} variant="outlined" color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={dialog.onConfirm}
          autoFocus
          variant="outlined"
          color="primary"
        >
          {dialog.loading ? "Lädt..." : "Bestätigen"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("de-DE", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleStatusChange = async (event) => {
    if (loadingStatusChange || swipe) {
      return;
    }

    setLoadingStatusChange(true);

    // call api
    let result = await fetch(`${baseUrl}/toDos/${props.todo.id}/status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
    }).catch((error) => {
      setSnackbar({
        open: true,
        message:
          "Fehler beim Ändern des Status. Bitte versuchen Sie es erneut.",
        severity: "error",
      });
      setLoadingStatusChange(false);
    });

    setLoadingStatusChange(false);

    if (!result.ok) {
      // show error message
      setSnackbar({
        open: true,
        message:
          "Fehler beim Ändern des Status. Bitte versuchen Sie es erneut.",
        severity: "error",
      });
      return;
    }

    setSnackbar({
      open: true,
      message: "Status erfolgreich geändert.",
      severity: "success",
    });

    // start swiping animation
    setSwipe(true);

    // after 1500ms, stop swiping animation and update todo
    setTimeout(() => {
      setSwipe(false);
      dispatch(
        todoActions.updateToDo({
          ...props.todo,
          status: props.todo.status === "OPEN" ? "DONE" : "OPEN",
        })
      );
    }, 1500);

    event.stopPropagation();
  };

  const handleDeletion = (event) => {
    if (loadingStatusChange) {
      return;
    }

    // ask for confirmation
    setDialog({
      open: true,
      title: "Bitte bestätigen Sie die Löschung",
      content: "Möchten Sie \"" + props.todo.title + "\" wirklich löschen?",
      loading: false,
      onConfirm: handleDeletionConfirmed,
    });
  };

  const handleDeletionConfirmed = async (event) => {
    setLoadingStatusChange(true);
    setDialog({ open: false });

    // call api
    let result = await fetch(`${baseUrl}/toDos/${props.todo.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
      },
    }).catch((error) => {
      setSnackbar({
        open: true,
        message: "Fehler beim Löschen. Bitte versuchen Sie es erneut.",
        severity: "error",
      });
    });

    setLoadingStatusChange(false);

    if (!result.ok) {
      // show error message
      setSnackbar({
        open: true,
        message: "Fehler beim Löschen. Bitte versuchen Sie es erneut.",
        severity: "error",
      });
      return;
    }

    setSnackbar({
      open: true,
      message: "Aufgabe erfolgreich gelöscht.",
      severity: "success",
    });
 
    dispatch(todoActions.removeToDo(props.todo));

    event.stopPropagation();
  };

  return (
    <Box className={swipe ? props.todo.status === "OPEN" ? "swipeRight" : "swipeLeft" : ""}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "20px",
          border: "5px solid lightgrey",
          "&:hover": {
            border: "5px solid #66BFBF",
          },

          // take as much height as needed
          height: "fit-content",
          position: "relative",
        }}
      >
        {(loadingStatusChange || swipe) && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, textAlign: "justify" }}
          >
            {props.todo.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
              <Chip
                label={
                  props.todo.assignee === null ?
                  "Nicht zugewiesen" :
                  "Zugewiesen an: " + props.todo.assignee}
                sx={{
                  backgroundColor: "transparent",
                  border: "2px solid #66BFBF",
                }}
              />
            {props.todo.dueDate && (
              <Chip
                label={
                  "Fällig bis: " +
                  formatTimestamp(props.todo.dueDate) +
                  " um " +
                  formatTime(props.todo.dueDate)
                }
                sx={{
                  backgroundColor: "transparent",
                  border: "2px solid #66BFBF",
                }}
              />
            )}
          </Box>
        </CardContent>
        <CardActions>
          <Tooltip
            title={
              props.todo.status === "OPEN"
                ? "Als Erledigt markieren"
                : "Als Offen markieren"
            }
          >
            <IconButton
              onClick={(event) => handleStatusChange(event)}
              aria-label="complete"
              sx={{
                color: "#829460",
              }}
            >
              {props.todo.status === "OPEN" ? (
                <CheckCircleOutlineIcon />
              ) : (
                <CheckCircleIcon />
              )}
            </IconButton>
          </Tooltip>

          {keycloak.hasResourceRole("delete-todo") && (
            <Tooltip title="Klicken, um ToDo zu löschen">
              <IconButton
                onClick={(event) => handleDeletion(event)}
                aria-label="delete"
                sx={{
                  color: "#E97777",
                }}
              >
                <RemoveCircleOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Klicken, um Beschreibung anzuzeigen">
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
              }}
              sx={{
                ml: "auto",
                // on click rotate 180°
                transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
              aria-label="expand"
              color="info"
            >
              <ExpandCircleDownRoundedIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography
              variant="body1"
              component="div"
              sx={{ flexGrow: 1, textAlign: "justify" }}
            >
              {props.todo.description.length > 0 ? (
                props.todo.description
              ) : (
                <i>Keine Beschreibung vorhanden.</i>
              )}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>

      {confirmationDialog}
    </Box>
  );
}
