import { combineReducers, configureStore } from "@reduxjs/toolkit";
import articlesSlice from "./article.slice";
import historySlice from "./history.slice";
import todoSlice from "./todo.slice";

export const reducer = combineReducers({
    articles: articlesSlice,
    histories: historySlice,
    todos: todoSlice,
});

export const store = configureStore({
    reducer: reducer,
});