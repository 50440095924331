import {Autocomplete, Button, Skeleton, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {connect, useDispatch} from "react-redux";
import {fetchArticles} from "../util/store/article.slice";

const mapStateToProps = (state) => {
    return {
        articles: state.articles.articles,
        loading: state.articles.loading,
        error: state.articles.error,
    };
};

function ArticleDropdown(props) {
    const dispatch = useDispatch();

    if (props.loading) {
        return (
            <Box>
                <Skeleton height={80}/>
            </Box>
        );
    }

    if (props.error) {
        return (
            <Box>
                <Button variant={"outlined"} onClick={
                    () => {
                        dispatch(fetchArticles());
                    }
                }>
                    <Typography color="error" variant={"body1"}>
                        Fehler beim Laden der Artikel. Klicken Sie hier um es erneut zu versuchen.
                    </Typography>
                </Button>
            </Box>
        );
    }

    return (
        <Box>
            <Autocomplete
                id="article-autocomplete"
                renderInput={(params) => <TextField {...params} label="Artikel"/>}
                options={props.articles}
                disablePortal
                getOptionLabel={(option) => {
                    if (option.articleCode) {
                        return option.articleCode.toString();
                    } else {
                        return "";
                    }
                }}
                autoHighlight
                value={props.value || null}
                onChange={(e, newValue) => {
                    props.onChange(e, newValue);
                }}
                onKeyPress={props.onKeyPress}
                isOptionEqualToValue={(option, value) =>
                    option.articleCode === value.articleCode
                }
            />
        </Box>
    );
}

export default connect(mapStateToProps)(ArticleDropdown);
