import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import keycloak from "../../keycloak/Keycloak";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
const namespace = "todos";

const initialState = {
    openToDos: [],
    loading: false,
    error: null,
};

export const fetchToDos = createAsyncThunk(
    namespace + "/fetchTodos",
    async () => {
        const result = await fetch(`${baseUrl}/toDos`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + keycloak.token,
            },
        });

        if (!result.ok) {
            throw new Error("Could not fetch ToDos.");
        } else {
            return await result.json();
        }
    }
);

const todoSlice = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        addToDo: (state, action) => {
            state.openToDos.push(action.payload);
        },
        removeToDo: (state, action) => {
            state.openToDos = state.openToDos.filter(
                (toDo) => toDo.id !== action.payload.id
            );
        },
        updateToDo: (state, action) => {
            const index = state.openToDos.findIndex(
                (toDo) => toDo.id === action.payload.id
            );
            state.openToDos[index] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchToDos.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchToDos.fulfilled, (state, action) => {
            state.openToDos = action.payload;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(fetchToDos.rejected, (state, action) => {
            state.error = action.error;
            state.loading = false;
        });
    },
});

export const todoActions = {
    ...todoSlice.actions,
};
export default todoSlice.reducer;
