/* eslint-disable jsx-a11y/iframe-has-title */
import { Box } from "@mui/system";
import { useState } from "react";
import {
  Alert,
  Divider,
  IconButton,
  MenuItem,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ToDoItem from "../components/todos/ToDoItem";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import keycloak from "../keycloak/Keycloak";
import AddToDoPopup from "../components/todos/AddToDoPopup";

const mapStateToProps = (state) => {
  return {
    todos: state.todos.openToDos,
    loading: state.todos.loading,
    error: state.todos.error,
  };
};

const availableOrders = [
  { label: "Fälligkeitsdatum aufsteigend", value: "dueDateAsc" },
  { label: "Fälligkeitsdatum absteigend", value: "dueDateDesc" },
  { label: "Erstellungsdatum aufsteigend", value: "creationDateAsc" },
  { label: "Erstellungsdatum absteigend", value: "creationDateDesc" },
  { label: "Status", value: "status" },
];

function ToDos(props) {
  /**
   * The Redux dispatch function.
   * @type {Dispatch<AnyAction>} dispatch
   */
  const dispatch = useDispatch();

  const [tab, setTab] = useState(1);

  const [orderBy, setOrderBy] = useState(availableOrders[0].value);

  const [search, setSearch] = useState("");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [addPopupOpen, setAddPopupOpen] = useState(false);

  if (props.error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "80vh",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          color="error"
          variant="h6"
          sx={{
            p: 5,
            m: 5,
            textAlign: "center",
            backgroundColor: "lightgrey",
            borderRadius: "10px",
          }}
        >
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "fit-content",
        width: "100%",
        flexDirection: "column",
        mb: 5,
      }}
    >
      <Tabs
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#66BFBF",
          },
          "& .Mui-selected": {
            color: "#66BFBF",
          },
          "& .MuiTab-root": {
            fontSize: "1.3rem",
            mx: 2,
          },
        }}
      >
        <Tab label="Alle" />
        <Tab label="Offen" />
        <Tab label="Erledigt" />
      </Tabs>

      <Divider sx={{ width: "90%", my: 3 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          gap: 2,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box>
          <TextField
            id="search"
            label="Suchen..."
            variant="outlined"
            sx={{
              width: "300px",
            }}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  sx={{
                    mr: 1,
                  }}
                />
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        {keycloak.hasResourceRole("add-todo") && (
          <Box>
            <Tooltip title="Element hinzufügen">
              <IconButton onClick={() => setAddPopupOpen(true)}>
                <AddCircleIcon
                  sx={{
                    color: "#66BFBF",
                    fontSize: "3rem",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Box>
          <TextField
            label="Sortieren nach..."
            select
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            sx={{
              minWidth: "300px",
              // align text to the left
              textAlign: "left",

              // label fix
              "& label": {
                color: "black",
              },
            }}
          >
            {availableOrders.map((order) => (
              <MenuItem key={order.value} value={order.value}>
                {order.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Divider sx={{ width: "90%", my: 3 }} />
      <Box
        sx={{
          // grid with 2 items per row on medium screens and 1 item per row on small screens
          display: "grid",
           gridTemplateColumns: { xs: "1fr", md: "1fr 1fr"},
          gap: 5,
          width: "90%",
          // stretch the grid items to the full width of the grid
          "& > *": {
            width: "100%",
            height: "fit-content"
          },
        }}
      >
        {props.todos
          .filter((todo) => {
            if (tab === 0) {
              return true;
            } else if (tab === 1) {
              return todo.status === "OPEN";
            } else {
              return todo.status === "DONE";
            }
          })
          .filter((todo) => {
            return (
              todo.title.toLowerCase().includes(search.toLowerCase()) ||
              todo.description.toLowerCase().includes(search.toLowerCase()) ||
              todo.status.toLowerCase().includes(search.toLowerCase()) ||
              todo.dueDate.toLowerCase().includes(search.toLowerCase()) ||
              todo.assignee.toLowerCase().includes(search.toLowerCase()) ||
              todo.creationDate.toLowerCase().includes(search.toLowerCase())
            );
          })
          .sort((a, b) => {
            switch (orderBy) {
              case "dueDateAsc":
                if (a.dueDate === null) {
                  return 1;
                } else if (b.dueDate === null) {
                  return -1;
                }
                return a.dueDate.localeCompare(b.dueDate);
              case "dueDateDesc":
                if (a.dueDate === null) {
                  return 1;
                } else if (b.dueDate === null) {
                  return -1;
                }
                return b.dueDate.localeCompare(a.dueDate);
              case "creationDateAsc":
                return a.creationDate.localeCompare(b.creationDate);
              case "creationDateDesc":
                return b.creationDate.localeCompare(a.creationDate);
              case "status":
                return a.status.localeCompare(b.status);
              default:
                return 0;
            }
          })
          .map((todo) => (
            <ToDoItem key={todo.id} todo={todo} setSnackbar={setSnackbar} />
          ))}
      </Box>
      {snackbar.open && (
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ open: false, message: "" })}
        >
          <Alert
            onClose={() => setSnackbar({ open: false, message: "" })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      {addPopupOpen && (
        <AddToDoPopup
          open={addPopupOpen}
          onClose={() => setAddPopupOpen(false)}
          setSnackbar={setSnackbar}
        />
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(ToDos);
